import Meta from "components/Meta"
import { getGlobals } from "storyblok/api"
import Image from "components/Image"

export default function Page({ meta }) {
  return (
    <>
      <Meta info={meta} />
      <main className="py-12 px-8 relative md:grid place-items-center md:h-[70dvh]">
        <div className="">
          <Image
            src="/assets/pets/lounging-dog.png"
            alt=""
            aria-hidden
            width={420}
            height={263}
            loader={undefined}
            className="mx-auto"
          />
          <h1 className="text-center pt-4">Page Not Found</h1>
          <p className="text-center pt-4 max-w-[65ch]">
            Sorry, the page you{"'"}re trying to access could not be found. It may have been moved or deleted. Please
            bear with us as we work to keep our site organized and up to date.
          </p>
        </div>
      </main>
    </>
  )
}

export async function getStaticProps({ preview = null }) {
  const globals = await getGlobals()

  return {
    props: {
      preview,
      globals,
    },
  }
}
